import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore'
import { Account } from './create-account/account'

class storeAccount {
  name:string;
  surname:string;
  email:string;
  companyName:string;
  JobTitle: string;

  constructor(account: Account) {
    this.name = account.name;
    this.surname = account.surname;
    this.email = account.email;
    this.companyName = account.company_name;
    this.JobTitle = account.job_title;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ContactServiceService {

  constructor( private firestore: AngularFirestore ) {
  }

  createAccountData(account: Account) {
        
    var n_account = new storeAccount(account);

    var itemDoc = this.firestore.doc<Account>('account_/'+ n_account.email)
    console.log('data input: ', n_account);
    const data_val = JSON.parse( JSON.stringify(n_account))
    return itemDoc.set(data_val)
}
}

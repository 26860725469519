// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCb49ydfIyIbTi5Obn5XpmHphjX_uqwhuQ",
    authDomain: "hesiod-trial.firebaseapp.com",
    databaseURL: "https://hesiod-trial-default-rtdb.firebaseio.com",
    projectId: "hesiod-trial",
    storageBucket: "hesiod-trial.appspot.com",
    messagingSenderId: "698200154900",
    appId: "1:698200154900:web:714efa52571cf1fdb55801",
    measurementId: "G-FP2N803PFF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

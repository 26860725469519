import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormBuilder, Validators} from '@angular/forms';
import { HttpResponse } from '@angular/common/http'
import {ActivatedRoute} from '@angular/router'
import { Subscription } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore'

import { TrialCustom } from './custom-validators'
import { TrialIdService } from '../trial-id.service';
import { TrialService } from './trialService'
import { ProcessReqService } from '../process-req.service'

import { CvService } from '../cv.service';

import { Trial } from './trial'

/**
 * TODO: Generate trial should be split in 3 components:
 * 1) whow you are and the trials you already defined (maximum of 10)
 *  => can be read from the firebase (index will need to be created, trial_id should include email OR we need to add the email (might be better))
 *  => service to get them, but not add a new component.
 * 2) Ability to edit a existing trial
 *  => every trial can have a edit simbol and a delete.
 *  => when selectedd, the "new area" can be used for edit
 * 3) Ability to create a new one (if less than 10)
 *  => always visibile
 * 4) Trials con be deleted ? 
 */

@Component({
  selector: 'app-generate-trial',
  templateUrl: './generate-trial.component.html',
  styleUrls: ['./generate-trial.component.css'],
  providers: [ProcessReqService]
})
export class GenerateTrialComponent implements OnInit, OnDestroy {

  f_keywords: FormControl;
  subscription_cv: Subscription;
  trialService: TrialService;

  trial: Trial;

  constructor(
              private trialId: TrialIdService,
              private cvService: CvService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private firestore: AngularFirestore,
              private http_req: ProcessReqService) { 

    this.subscription_cv = cvService.cvLoaded$.subscribe(
      cv => {
        if (this.trial.data.cvs !== null) {
          this.trial.data.cvs.push(cv)
          this.trial.data.valid_cvs = this.trial.data.cvs.length >= 10
        } else {
          this.trial.data.cvs = [ cv ]
        }
        console.log("cv received: ", cv)
      }
    )

    this.f_keywords = this.fb.control('', [
      Validators.required,
      TrialCustom.contentValidator({invalidContent: true }),
      TrialCustom.lenghtValidator({invalidLenght: true })
    ])

    this.f_keywords.valueChanges.subscribe(x => {
      this.trial.updateKeywords(x);
    })
    
    this.trialService = new TrialService(firestore)

  }

  ngOnInit(): void {
    this.trial = new Trial()
    this.trial.data.email = this.route.snapshot.params['emailid']
    this.trial.data.trial_id = makeid(4)
    this.trialId.newTrialID(this.trial.data.trial_id)
    console.log("email received: ", this.trial.data.email)
    console.log(this.trial)
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription_cv.unsubscribe();
  }

  onSubmit() {
    this.trialService.createTrialdata(this.trial).then(
      res=> {
        console.log('submital OK Lo he cambiado')
        const job_id = this.trial.data.email + '_' + this.trial.data.trial_id
        this.trial = new Trial()
        console.log("job_id: "+ job_id)
        this.http_req.req_process(job_id).subscribe(
          { next: (value: HttpResponse<string>) => {
            console.log(value)
          },  
            error: (error: string) => {console.log(error)}
          });
        });
  }

}

function makeid(length: number) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'
import {Trial, TrialData} from './trial'

export class TrialService {
    constructor( private firestore: AngularFirestore ) {
    }

    createTrialdata(trial: Trial) {
        
        const trial_id = trial.data.trial_id
        var itemDoc = this.firestore.doc<TrialData>('trial_/'+ trial.data.email + '_' + trial.data.trial_id)
        console.log('data input: ',trial.data);
        const data_val = JSON.parse( JSON.stringify(trial.data))
        return itemDoc.set(data_val)
    }

}
<div class="trial_user">
    <form [formGroup]="f_account" *ngIf="credential === null">
        <mat-grid-list cols="1" rowHeight="25px" [gutterSize]="'4px'">
            <mat-grid-tile [rowspan]="2">
                <h1>cuenta de prueba</h1>
            </mat-grid-tile>
            <mat-grid-tile [rowspan]="2">
                <mat-form-field class="email" >
                    <mat-label>email</mat-label>
                    <input type="email" matInput [formControl]="f_email"
                        placeholder="ej: cuenta@corp.com" name="email">
                    <mat-hint>cuenta@corp.com</mat-hint>
                    <mat-error *ngIf="f_email.hasError('required')">
                        email es <strong>obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f_email.hasError('email')">
                        input <strong>no</strong> tiene formato de email
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [rowspan]="6">
                <mat-form-field class="password">
                    <mat-label>clave</mat-label>
                    <input type="password" matInput [formControl]="f_password" 
                        name="password" >
                    <mat-hint>clave: mínimo 7 caracteres, mayúsculas, minúsculas, un caracter especial, una cifra</mat-hint>

                    <mat-error *ngIf="f_password.hasError('required')">
                        clave es <strong>obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f_password.hasError('hasNumber')">
                        clave <strong>debe</strong> tener al menos una cifra
                    </mat-error>
                    <mat-error *ngIf="f_password.hasError('hasCapitalCase')">
                        clave <strong>debe</strong> tener al menos una mayúscula
                    </mat-error>
                    <mat-error *ngIf="f_password.hasError('hasSmallCase')">
                        clave <strong>debe</strong> tener al menos una minúscula
                    </mat-error>
                    <mat-error *ngIf="f_password.hasError('hasSpecialCharacters')">
                        clave <strong>debe</strong> tener al menos un caracter especial
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="status=='create'" [rowspan]="2">
                <mat-form-field class="confirmPassword">
                    <mat-label>confirma la clave</mat-label>
                    <input type="password" matInput [formControl]="f_confirmPassword" 
                         name="confirmPassword" >
                    <mat-error *ngIf="f_confirmPassword.hasError('required')">
                        clave <strong>requiere</strong> confirmación
                    </mat-error>
                    <mat-error *ngIf="f_confirmPassword.hasError('NoPasswordMatch')">
                        clave <strong>no</strong> coincide
                    </mat-error>             
                </mat-form-field>
            </mat-grid-tile>
            
            <mat-grid-tile *ngIf="status=='create'" [rowspan]="2">
                <mat-form-field class="companyName" >
                    <mat-label>nombre compañia</mat-label>
                    <input type="text" matInput [formControl]="f_companyName">
                    <mat-error *ngIf="f_companyName.hasError('required')">
                        nombre de compañia es <strong>obligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="status=='create'" [rowspan]="2">
                <mat-form-field class="name" >
                    <mat-label>nombre</mat-label>
                    <input type="text" matInput [formControl]="f_name">
                    <mat-error *ngIf="f_name.hasError('required')">
                        nombre es <strong>obligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="status=='create'" [rowspan]="2">
                <mat-form-field class="surname" >
                    <mat-label>apellido</mat-label>
                    <input type="text" matInput [formControl]="f_surname">
                    <mat-error *ngIf="f_surname.hasError('required')">
                        apellido es <strong>obligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="status=='create'" [rowspan]="2">
                <mat-form-field class="jobtitle" >
                    <mat-label>puesto en la empresa</mat-label>
                    <input type="text" matInput [formControl]="f_jobTitle">
                    <mat-error *ngIf="f_surname.hasError('required')">
                        puesto es <strong>obligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>   

            <mat-grid-tile *ngIf="status!='create'" [rowspan]="2">
                <button mat-button type="submit" (click)="onLogin()" [disabled]="f_email.errors !== null || f_password.errors !== null || f_account.valid">acceso a la cuenta</button>
                <mat-error *ngIf="error_login">
                    usuario / clave <strong>desconocida</strong>
                </mat-error>
                <button mat-button type="submit" (click)="createAccount()">crear cuenta</button> 
            </mat-grid-tile>


            <mat-grid-tile *ngIf="status=='create'">
                <button mat-raised-button (click)="openDialog()" [disabled]="!f_account.valid">revisa y aprueba las condiciones</button>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="status=='create'" [rowspan]="4">

                <div>
                    <p>
                        creando la cuenta, doy permiso a Sunappear o sus socios comerciales a utilizar mi email con asuntos referentes a esta prueba, mientras esta cuenta este activada.
                    </p>
                    <p>
                        para desactivar la cuenta seguir las instrucciones en el documento de terminos y condiciones.
                    </p>
                    <p>
                    <button mat-button type="submit"  (click)="onSubmit()" [disabled]="!f_account.valid || !accepted">crear cuenta</button>
                    <mat-error *ngIf="error_create">
                        email en el sistema
                    </mat-error>
                    </p>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>


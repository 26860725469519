import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  accepted: boolean;
}


@Component({
  selector: 'cond-review',
  templateUrl: 'cond-review.component.html',
})
export class CondReviewComponent {

  constructor(
    public dialogRef: MatDialogRef<CondReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

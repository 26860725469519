<div class="trial-definition">
    <div class="acc-creation">
        <h1 class="center">definición de prueba</h1>
    </div>
    <mat-list class="trial_header" role='list'>
        <mat-list-item role="listitem">
            <mat-label>email</mat-label>
        </mat-list-item>
        <mat-list-item role="listitem">{{trial.data.email}}</mat-list-item>
        <mat-list-item>
            <mat-label>id de prueba</mat-label>
        </mat-list-item>
        <mat-list-item role="listitem">{{trial.data.trial_id}}</mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list role='list'>
        <mat-list-item role="listitem">
            <mat-label>cv cargados</mat-label>
        </mat-list-item>
        <mat-list-item *ngFor="let cv of trial.data.cvs">
            <div class="file-name">
            {{cv}}
            </div>
        </mat-list-item>
    </mat-list>
    <mat-grid-list cols="1" rowHeight="25px" [gutterSize]="'4px'">
        <mat-grid-tile [rowspan]="3">
            <div class="file-upload">
                <app-file-uploader [email]="trial.data.email" [job_ticket]="trial.data.trial_id">loading</app-file-uploader>
            </div>

        </mat-grid-tile>
        <mat-grid-tile>
            <div class="file-upload">
                <mat-error *ngIf="trial.data.valid_cvs !== true">
                    se requieren mínimo de <strong>10</strong> ficheros
                </mat-error>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="3">
            <div class="keywords">
                <mat-form-field>
                    <mat-label>keywords</mat-label>
                    <input type="text" matInput [formControl]="f_keywords"
                    placeholder="lista de las palabras claves separadas por espacios o coma" name="keywords">
                    <mat-hint>comprador, tecnología, ... mínimo 10 palabras 4 caracteres</mat-hint>
                    <mat-error *ngIf="f_keywords.hasError('required')">
                        list de palabras claves es <strong>obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f_keywords.hasError('invalidLenght')">
                        mínimo de <strong>10</strong> palabras clave
                    </mat-error>
                    <mat-error *ngIf="f_keywords.hasError('invalidContent')">
                        palabras clave mínimo de <strong>4</strong> caracteres 
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="2">
            <button mat-button type="submit" (click)="onSubmit()" [disabled]="(f_keywords.errors !== null) || (trial.data.valid_cvs !== true)">registra prueba</button>
        </mat-grid-tile>
    </mat-grid-list>
</div>

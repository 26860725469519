import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

import { CvService } from '../cv.service';



@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  @ViewChild('fileInput')
  fileInput: ElementRef;
  
  @Input('email')
  email: string | null;
  @Input('job_ticket')
  job_ticket: string | null 

  file: File | null = null;
  storagePath =  '/uploads';
  error_uploading:boolean = false;
  isLoading:boolean = false;

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
    console.log('file: ', this.file)
    this.pushFileToStorage()
  }

  pushFileToStorage(): void {
    var storage = this.storagePath;

    /// uploading file
    // if job_ticket and email are not null, then set storage path if not use default.
    if ( this.email !== null && this.job_ticket !== null) {
      storage = '/' + this.email + '_' + this.job_ticket
    }

    const filePath = `${storage}/${this.file.name}`;

    this.afStorage.upload(filePath, this.file).snapshotChanges().subscribe(
        value => {
          console.log("change of state:", value.state)
          this.error_uploading = false
          if (value.state === 'success') {
            console.log("success status")
            this.cvService.cvLoaded(this.file.name)
            this.isLoading = false;
          }
          if (value.state === 'running') {
            this.isLoading = true;
          }
        },
        error => {
          console.log("error", error)
          this.error_uploading = true;
          this.isLoading = false;
        }
      );
  
  }
  

  constructor(private afStorage: AngularFireStorage, 
              private cvService: CvService
              ) {
  }

  ngOnInit(): void {
  }

}

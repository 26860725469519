import { createAotCompiler } from '@angular/compiler';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountComponent} from './create-account/create-account.component'
import { GenerateTrialComponent } from './generate-trial/generate-trial.component'
import { fromEventPattern } from 'rxjs';

const routes: Routes = [
  //{ path: 'index', redirectTo: '/login' , pathMatch: 'full'},
  { path: '', component: CreateAccountComponent},
  { path: 'login', component: CreateAccountComponent},
  { path: 'gtrial', component: GenerateTrialComponent },
  {path: 'gtrial/:emailid', component: GenerateTrialComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

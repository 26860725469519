import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CvService {

  private cv = new Subject<string>();

  cvLoaded$ = this.cv.asObservable();

  cvLoaded(cv: string){
    this.cv.next(cv)
  } 


  constructor() { }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorStateMatcher } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon'; // cloud_upload
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';





import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { CreateAccountComponent } from './create-account/create-account.component';
import { CondReviewComponent } from './cond-review/cond-review.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { GenerateTrialComponent } from './generate-trial/generate-trial.component';

import { DirtyErrorStateMatcher } from './dirty-error-state.matcher';
import { HttpTestComponent } from './http-test/http-test.component';
//import { ProcessReqService } from './process-req.service'



@NgModule({
  declarations: [
    AppComponent,
    CreateAccountComponent,
    CondReviewComponent,
    FileUploaderComponent,
    GenerateTrialComponent,
    HttpTestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    /////
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatGridListModule,
    MatCheckboxModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ////
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule
  ],
  providers: [
    {provide: ErrorStateMatcher,
    useClass: DirtyErrorStateMatcher},
    //{provide: ProcessReqService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ProcessReqService } from '../process-req.service'
import { HttpResponse } from '@angular/common/http'

@Component({
  selector: 'app-http-test',
  templateUrl: './http-test.component.html',
  styleUrls: ['./http-test.component.css'],
  providers: [ProcessReqService]
})
export class HttpTestComponent implements OnInit {

  constructor(private http_req: ProcessReqService) { }

  ngOnInit(): void {
    const job_id = 'jaime.ayats@pandora-doc.com_OSyk'

    this.http_req.req_process(job_id).subscribe(
      {next: (value: HttpResponse<string>) => {
        console.log(value)
      },
      error: (error) => console.log(error)
    }
    )

  }

}

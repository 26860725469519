<h1 mat-dialog-title>condiciones de uso de la prueba</h1>
<div doc-conditions>
  <iframe src="assets/trial_acceptance.pdf" width="90%"></iframe>
</div>
<div acceptance>
  <div>
    <mat-checkbox [(ngModel)]="data.accepted">acepto las condiciones de la prueba</mat-checkbox>
  </div>
  <div>  
    <div>
      descargar el documento usando las opciones del navegador.
    </div>
    <div>
      las condiciones de uso pueden cambiar.
      para borrar la cuenta, enviar un e-mail a info@pandora-doc.com.
    </div>
    <button mat-button (click)="onNoClick()">no acepto las condiciones</button>
    <button mat-button *ngIf="data.accepted" [mat-dialog-close]="data.accepted" cdkFocusInitial>seguir</button>
  </div>
  </div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class TrialIdService {

  private trialId = new Subject<string>();

  newTrialId$ = this.trialId.asObservable();

  newTrialID(trialId: string){
    this.trialId.next(trialId)
  } 


  constructor() { }
}

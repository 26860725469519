import {clean_keywords} from './support_functions'

export class TrialData {
    email: string;
    trial_id: string;
    keywords: Array<string> | null;
    cvs : Array<string> | null;
    valid_cvs: boolean;

    constructor( ) {
        this.email = null;
        this.trial_id = null;
        this.keywords = null;
        this.cvs= null;
    }

}


export class Trial {

    data: TrialData;


    constructor( ) {
        this.data = new TrialData()
    }

    updateKeywords(input: string): void {
        this.data.keywords = clean_keywords(input)
    }

    getKeywords(): string {
        return this.data.keywords.toString()
    }

    getData(): TrialData {
        return this.data
    }



}
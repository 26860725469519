import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { clean_keywords } from './support_functions'

export class TrialCustom {

  static lenghtValidator(error: ValidationErrors): ValidatorFn {
    return(control: AbstractControl): { [key: string]: any} | null => {
      if (!control.value) {
        return null
      }
      const n_keywords = clean_keywords(control.value).length >= 10
      return n_keywords ? null : error;


    }
  }

   static contentValidator(error: ValidationErrors): ValidatorFn {
      return(control: AbstractControl): { [key: string]: any} | null => {
        if (!control.value) {
          return null;
        }

        var key_list = clean_keywords(control.value).every(function(e: string): boolean {
         return e.length >= 4
        })
        
        return key_list ? null : error;
  
  
      }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

class ReqBody {
  token: string = 'prueba'
  job_id: string

  constructor(job_id: string) {
    this.job_id = job_id
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProcessReqService {

 request_url = 'https://trialser.pandora-doc.com/trial'
 // request_url = 'https://trial-r3pvuo3jkq-uc.a.run.app/trial'


  error: boolean = false

  constructor(private http: HttpClient) {
   }
  


   req_process(job_id: string) {

    const options = {
      responseType: 'text' as const,
      observe: 'response' as const,
      withCredentials: false,
      reportProgress: true
    };

     const body_request = new ReqBody(job_id)
     console.log("sending request, job_id: " + job_id)
     return this.http.post(this.request_url, body_request, options )
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ValidationErrors, FormControl, FormBuilder, AbstractControl, Validators, FormGroup } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router'
import firebase from 'firebase';

import { Account } from './account';
import { CustomValidators } from './custom-validators'
import { CondReviewComponent } from '../cond-review/cond-review.component'
import { ContactServiceService } from '../contact-service.service'
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})

/**
 * login | create
 * IF LOGIN |DON't show after password line
 * IF Create: Show every thing, except the login button
 * WHEN successfully created, GO BACK to LOGIN status (done at root level, not here)
 */




export class CreateAccountComponent implements OnInit {

  @Input() status:string;
  
  account: Account;
  confirmPassword: string;
  error_login: boolean;
  error_create: boolean;
  credential: firebase.auth.UserCredential | null;


  accepted: boolean;

  f_email: FormControl;
  f_password: FormControl;
  f_confirmPassword: FormControl;
  f_name: FormControl;
  f_surname: FormControl;
  f_jobTitle: FormControl;
  f_companyName: FormControl;

  f_account: FormGroup;

  ngOnInit() {
    this.account = {
      email : "",
      password: "",
      name: "",
      surname: "",
      job_title: "",
      company_name: "",
      conf_acc: false
    }

    this.confirmPassword = "";
    this.error_login = false;
    this.error_create = false;
    this.credential = null;

    console.log("version del 25 Marzo")

  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private contactService: ContactServiceService,
    private Router: Router) {

    this.f_email = this.fb.control('', [
      Validators.email, Validators.required
    ])

    this.f_password = this.fb.control('', [
      Validators.required,
      Validators.minLength(7), 
      CustomValidators.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      )
    ])

    this.f_confirmPassword = this.fb.control('', [
      Validators.required
    ])
    

    this.f_companyName = this.fb.control('', [
      Validators.required
    ])

    this.f_jobTitle = this.fb.control('', [
      Validators.required
    ])
    
    this.f_name = this.fb.control('', [
      Validators.required
    ])

    this.f_surname = this.fb.control('', [
      Validators.required
    ])

    this.f_jobTitle = this.fb.control('',
      Validators.required
    )


    
   this.f_account = fb.group(
    {
      email: this.f_email,
      password: this.f_password,
      confirmPassword: this.f_confirmPassword,
      companyName: this.f_companyName,
      name: this.f_name,
      surname: this.f_surname,
      jobTitle: this.f_jobTitle
    },
    {
      validators: [passwordMatchValidator]
    })

      /*
  this.reactiveForm.get("firstname").valueChanges.subscribe(x => {
   console.log('firstname value changed')
   console.log(x)
  })
  */


  this.f_account.get("email").valueChanges.subscribe(x => {
    this.account.email = x;

  })

  this.f_account.get("password").valueChanges.subscribe(x => {
    this.account.password = x
  })

  this.f_account.get("confirmPassword").valueChanges.subscribe(x => {
    this.confirmPassword = x
  })

  this.f_account.get("companyName").valueChanges.subscribe(x => {
    this.account.company_name = x
  })

  this.f_account.get("name").valueChanges.subscribe(x => {
    this.account.name = x
  })

  this.f_account.get("surname").valueChanges.subscribe(x => {
    this.account.surname = x
  })  

  this.f_account.get("jobTitle").valueChanges.subscribe(x => {
    this.account.job_title = x
  })  

  }


  openDialog () {
    const dialogRef = this.dialog.open(CondReviewComponent, {
      width: '100%',
      data: {name: this.accepted}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.accepted = result;
    });
  }

  SendVerificationMail(): Promise<void> {
    return this.afAuth.currentUser.then(
      user => {
        user.sendEmailVerification().then(
          () => this.Router.navigate(['login'])
        )
      }
    )
  }

  doRegister(email: string, password: string){
    this.error_create = false;
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(res => {
        this.error_create = false;
        resolve(res);

        this.contactService.createAccountData(this.account)
        this.SendVerificationMail()
      }, err => { 
        this.error_create = true; 
        reject(err)
      }) 
    })
  }  
  // create an error variable to report error when creating an account

  login( email: string, password: string) {                   // this should be used in another place!!!
    this.error_login = false;
    this.afAuth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        console.log('something is wrong:',error.message); // create an error variable to report error close to login
        this.error_login = true;
      })
      .then(userCredential => {
        if(userCredential) {
          if(userCredential.user.emailVerified !== true) {
            this.SendVerificationMail();
            window.alert('valida tu email, te hemos enviado un email de validación')
          } else {
            this.credential = userCredential;
            console.log('successful login:', userCredential)
            this.error_login = false;
            this.Router.navigate(['gtrial', email])
          }
        }
      })
  }

  onSubmit() {
    // Here we need to create the account. Next step.
    console.log(`Create Account: ${this.account.email}, ${this.account.password}, ${this.confirmPassword}, ${this.account.name}, ${this.account.surname}, ${this.account.company_name}`)
    this.doRegister(this.account.email, this.account.password)
  }

  onLogin() {
    // here we do a login in to a created account.
    console.log(`Login into Account: ${this.account.email}, ${this.account.password} xx`)
    this.login(this.account.email, this.account.password)
  }

  createAccount() {
    this.status = 'create'
  }



}




function passwordMatchValidator(g: AbstractControl): ValidationErrors | null {
  if (g.get('password').value === g.get('confirmPassword').value) {
    return null
  }
  g.get('confirmPassword')?.setErrors({NoPasswordMatch: true})
  return {NoPasswordMatch: true}
}

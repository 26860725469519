<div class="file-upload">
  <div *ngIf="error_uploading===false">
    <input type="file" style="display: none" #fileInput accept="application/pdf" (change)="onChangeFileInput()" />
    <button mat-button color="basic" class="file-select-button" (click)="onClickFileInputButton()">
        <mat-icon>cloud_upload</mat-icon>
      attach file
    </button>
    <mat-progress-spinner *ngIf="isLoading" 
    style="display: flex; justify-content: center; align-items: center; background: white;"
      color="primary" 
      mode="indeterminate"
      diameter=15>
    </mat-progress-spinner>
  </div>
  <div>
    <mat-error *ngIf="error_uploading">error subiendo fichero <strong>has hecho login?</strong></mat-error>
  </div>
</div>
